/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
// import {Helmet} from "react-helmet"

function Codepen() {

    return (
        <div dangerouslySetInnerHTML={{__html: `<script async src="//codepen.io/assets/embed/ei.js"></script>`}}/>
    )
}

Codepen.defaultProps = {
};

Codepen.propTypes = {
};

export default Codepen
