import React from "react"
import PropTypes from "prop-types";

function Gist({ src }) {
    // Todo: this works only on production build, during development
    // it doesn't show the gist
    return (
        <div dangerouslySetInnerHTML={{__html: `<script src="${src}"></script>`}}/>
    )
}

Gist.defaultProps = {

};

Gist.propTypes = {
    src: PropTypes.string.isRequired
};

export default Gist
