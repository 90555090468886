import screenshot1 from "../../../../../public/screenshot/1.png";
import screenshot2 from "../../../../../public/screenshot/2.png";
import screenshot3 from "../../../../../public/screenshot/3.png";
import Codepen from "../../../../src/components/codepen";
import * as React from 'react';
export default {
  screenshot1,
  screenshot2,
  screenshot3,
  Codepen,
  React
};