// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-resume-js": () => import("./../src/pages/about/resume.js" /* webpackChunkName: "component---src-pages-about-resume-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-bandwaggon-js": () => import("./../src/pages/work/bandwaggon.js" /* webpackChunkName: "component---src-pages-work-bandwaggon-js" */),
  "component---src-pages-work-company-thread-js": () => import("./../src/pages/work/company-thread.js" /* webpackChunkName: "component---src-pages-work-company-thread-js" */),
  "component---src-pages-work-scherago-js": () => import("./../src/pages/work/scherago.js" /* webpackChunkName: "component---src-pages-work-scherago-js" */),
  "component---src-templates-blog-template-js": () => import("./../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

